import { HotelSearchQuery_Selector } from '$lib/gen/hotel/v1/query_pb'

export interface Selector {
  title: string
  contentIds: string[]
  iconUrl: string
  slug: string
  id: HotelSearchQuery_Selector
  type: 'theme' | 'category'
}

export function getSelectorById(id: string): Selector {
  return selectors.find((s) => s.slug === id)
}

export function findSelectorName(id: string): string {
  return selectors.find((s) => s.slug === id)?.title ?? 'Hotel'
}

export const simpleSelectors: Selector[] = [
  {
    title: 'Homes',
    contentIds: [
      'PRIVATE_VACATION_HOME',
      'APARTMENT',
      'GUESTHOUSE',
      'VILLA',
      'APARTHOTEL',
      'CONDO',
      'PENSION',
      'POUSADA_PORTUGAL',
      'POUSADA_BRAZIL',
      'COTTAGE',
      'TREE_HOUSE',
      'TOWN_HOUSE',
      'COUNTRY_HOUSE',
      'AGRITOURISM_PROPERTY',
      'PROPERTY',
      'RESIDENCE',
      'RYOKAN',
    ],
    iconUrl: 'private-vacation-home.svg',
    slug: 'homes',
    id: HotelSearchQuery_Selector.HOMES,
    type: 'category',
  },
  {
    title: 'Hotels',
    contentIds: [
      'HOTEL',
      'BED_BREAKFAST',
      'INN',
      'CAMPSITE',
      'HOLIDAY_PARK',
      'LODGE',
      'APARTHOTEL',
      'MOTEL',
      'RESORT',
      'ALL_INCLUSIVE_PROPERTY',
      'CONDOMINIUM_RESORT',
    ],
    iconUrl: 'hotel.svg',
    slug: 'hotel',
    id: HotelSearchQuery_Selector.HOTEL,
    type: 'category',
  },
  {
    title: 'Hostels',
    contentIds: ['HOSTEL_BACKPACKER_ACCOMMODATION', 'MOTEL'],
    iconUrl: 'hostel.svg',
    slug: 'hostel',
    id: HotelSearchQuery_Selector.HOSTEL,
    type: 'category',
  },
]

export const selectors: Selector[] = [
  {
    title: 'Homes',
    contentIds: [
      'PRIVATE_VACATION_HOME',
      'APARTMENT',
      'GUESTHOUSE',
      'VILLA',
      'APARTHOTEL',
      'CONDO',
      'PENSION',
      'POUSADA_PORTUGAL',
      'POUSADA_BRAZIL',
      'COTTAGE',
      'TREE_HOUSE',
      'TOWN_HOUSE',
      'COUNTRY_HOUSE',
      'AGRITOURISM_PROPERTY',
      'PROPERTY',
      'RESIDENCE',
      'RYOKAN',
    ],
    iconUrl: 'private-vacation-home.svg',
    slug: 'homes',
    id: HotelSearchQuery_Selector.HOMES,
    type: 'category',
  },
  {
    title: 'Hotel',
    contentIds: [
      'HOTEL',
      'BED_BREAKFAST',
      'INN',
      'CAMPSITE',
      'HOLIDAY_PARK',
      'LODGE',
      'APARTHOTEL',
    ],
    iconUrl: 'hotel.svg',
    slug: 'hotel',
    id: HotelSearchQuery_Selector.HOTEL,
    type: 'category',
  },
  {
    title: 'Hostel',
    contentIds: ['HOSTEL_BACKPACKER_ACCOMMODATION'],
    iconUrl: 'hostel.svg',
    slug: 'hostel',
    id: HotelSearchQuery_Selector.HOSTEL,
    type: 'category',
  },
  {
    title: 'Business',
    contentIds: ['BUSINESS', 'BUSINESS_PROPERTY', 'JAPANESE_BUSINESS_PROPERTY'],
    iconUrl: 'business.svg',
    slug: 'business',
    id: HotelSearchQuery_Selector.BUSINESS,
    type: 'theme',
  },
  {
    title: 'Beach',
    contentIds: ['BEACH', 'BEACH_PROPERTY'],
    iconUrl: 'beach.svg',
    slug: 'beach',
    id: HotelSearchQuery_Selector.BEACH,
    type: 'theme',
  },
  {
    title: 'Child Friendly',
    contentIds: ['FAMILY', 'FAMILY_PROPERTY'],
    iconUrl: 'family.svg',
    slug: 'family',
    id: HotelSearchQuery_Selector.FAMILY,
    type: 'theme',
  },
  {
    title: 'Golf',
    contentIds: ['GOLF', 'GOLF_PROPERTY'],
    iconUrl: 'golf.svg',
    slug: 'golf',
    id: HotelSearchQuery_Selector.GOLF,
    type: 'theme',
  },
  {
    title: 'Hot Springs',
    contentIds: ['HOT_SPRINGS', 'HOT_SPRINGS_PROPERTY'],
    iconUrl: 'hot-springs.svg',
    slug: 'hot-springs',
    id: HotelSearchQuery_Selector.HOT_SPRINGS,
    type: 'theme',
  },
  {
    title: 'Urban',
    contentIds: ['CITY'],
    iconUrl: 'city.svg',
    slug: 'city',
    type: 'theme',
    id: HotelSearchQuery_Selector.CITY,
  },
  {
    title: 'Pet Friendly',
    contentIds: ['PET_FRIENDLY_PROPERTY'],
    iconUrl: 'pet-friendly.svg',
    slug: 'pet-friendly',
    type: 'theme',
    id: HotelSearchQuery_Selector.PET_FRIENDLY,
  },
  {
    title: 'Casino',
    contentIds: ['CASINO', 'CASINO_PROPERTY'],
    iconUrl: 'casino.svg',
    slug: 'casino',
    type: 'theme',
    id: HotelSearchQuery_Selector.CASINO,
  },
  {
    title: 'Spa',
    contentIds: ['SPA', 'SPA_PROPERTY'],
    iconUrl: 'spa.svg',
    slug: 'spa',
    type: 'theme',
    id: HotelSearchQuery_Selector.SPA,
  },
  {
    title: 'Luxury',
    contentIds: ['LUXURY_PROPERTY', 'ALL_INCLUSIVE_PROPERTY'],
    iconUrl: 'luxury.svg',
    slug: 'luxury',
    type: 'theme',
    id: HotelSearchQuery_Selector.LUXURY,
  },
  {
    title: 'Sustainable',
    contentIds: ['GREEN_SUSTAINABLE_PROPERTY'],
    iconUrl: 'sustainable.svg',
    slug: 'sustainable',
    type: 'theme',
    id: HotelSearchQuery_Selector.SUSTAINABLE,
  },
  {
    title: 'Resort',
    contentIds: ['RESORT', 'ALL_INCLUSIVE_PROPERTY', 'CONDOMINIUM_RESORT'],
    iconUrl: 'resort.svg',
    slug: 'resort',
    type: 'category',
    id: HotelSearchQuery_Selector.RESORT,
  },
  {
    title: 'Budget',
    contentIds: ['MOTEL', 'HOSTEL_BACKPACKER_ACCOMMODATION'],
    iconUrl: 'motel.svg',
    slug: 'motel',
    id: HotelSearchQuery_Selector.MOTEL,
    type: 'category',
  },
  {
    title: 'Unique',
    contentIds: [
      'CASTLE',
      'RANCH',
      'HOUSEBOAT',
      'CRUISE',
      'SAFARI_TENTALOW',
      'PALACE',
      'RYOKAN',
      'CHALET',
      'RIAD',
      'HOLIDAY_PARK',
      'CAMPSITE',
    ],
    iconUrl: 'motel.svg',
    slug: 'motel',
    id: HotelSearchQuery_Selector.UNIQUE,
    type: 'category',
  },
  {
    title: 'Nature',
    contentIds: ['NATURAL'],
    iconUrl: 'natural.svg',
    slug: 'natural',
    type: 'theme',
    id: HotelSearchQuery_Selector.NATURAL,
  },
  {
    title: 'Adventure Sports',
    contentIds: ['ADVENTURE_SPORT', 'ADVENTURE_SPORT_PROPERTY'],
    iconUrl: 'adventure-sport.svg',
    slug: 'adventure-sport',
    type: 'theme',
    id: HotelSearchQuery_Selector.ADVENTURE_SPORTS,
  },
  {
    title: 'Winery',
    contentIds: ['WINERY', 'WINERY_PROPERTY'],
    iconUrl: 'winery.svg',
    slug: 'winery',
    type: 'theme',
    id: HotelSearchQuery_Selector.WINERY,
  },
  {
    title: 'Shopping',
    contentIds: ['SHOPPING', 'SHOPPING_PROPERTY'],
    iconUrl: 'shopping.svg',
    slug: 'shopping',
    type: 'theme',
    id: HotelSearchQuery_Selector.SHOPPING,
  },
  {
    title: 'Ski',
    contentIds: ['SKI', 'SKI_PROPERTY'],
    iconUrl: 'ski.svg',
    slug: 'ski',
    type: 'theme',
    id: HotelSearchQuery_Selector.SKI,
  },
  {
    title: 'Romantic',
    contentIds: ['ROMANTIC', 'ROMANTIC_PROPERTY'],
    iconUrl: 'romantic.svg',
    slug: 'romantic',
    type: 'theme',
    id: HotelSearchQuery_Selector.ROMANTIC,
  },
  {
    title: 'Boutique',
    contentIds: ['BOUTIQUE_PROPERTY'],
    iconUrl: 'boutique.svg',
    slug: 'boutique',
    type: 'theme',
    id: HotelSearchQuery_Selector.BOUTIQUE,
  },
  {
    title: 'Historic',
    contentIds: ['HISTORIC_PROPERTY'],
    iconUrl: 'historic.svg',
    slug: 'historic',
    type: 'theme',
    id: HotelSearchQuery_Selector.HISTORIC,
  },
]
