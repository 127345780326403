<script lang="ts">
  import type { HotelCardFieldsFragment } from '$lib/queries/fragments/generated/HotelCardFields'
  import { toAmount } from '$lib/utils/currency'
  import { collectNodes } from '$lib/utils/edges'
  import { getNameFromCountryId } from 'src/utils/country-codes'
  import { getDiscount, isAGem, isNew } from 'src/utils/stays/badges'
  import { findCategoryName } from 'src/utils/stays/categories'
  import { getLayout, getNumBedroomDescription } from 'src/utils/stays/layout'
  import { track } from 'src/utils/track'
  import { createEventDispatcher } from 'svelte'
  import Tooltip from '../Tooltip.svelte'
  import Amount from '../ui/Amount.svelte'
  import ImageCarousel from './ImageCarousel.svelte'

  export let hotelProperty: HotelCardFieldsFragment | null = null
  export let numNights: number
  export let fetching = false
  export let destinationCountryName: string | null = null
  export const excludedCategories = [/badge/] // Filter out Covid-Safe badges
  export let isMarker = false
  export let isModal = false
  export let currency: string = 'USD'

  $: photos = [
    hotelProperty?.heroPhoto,
    ...collectNodes(hotelProperty?.photos).filter(
      (photo) =>
        photo.id != hotelProperty?.heroPhoto?.id &&
        !excludedCategories.some((cat) => cat.test(photo.category)),
    ),
  ].filter(Boolean)

  $: gem = isAGem(hotelProperty?.starRating, hotelProperty?.guestRating)
  $: newProperty = isNew(hotelProperty?.createdAt)
  $: discount = getDiscount(hotelProperty)
  $: countryName =
    destinationCountryName != null
      ? destinationCountryName
      : getNameFromCountryId(hotelProperty.address.countryCode)

  $: layout = getLayout(collectNodes(hotelProperty?.rooms))
  $: numBedroomDescription = getNumBedroomDescription(layout)

  const dispatch = createEventDispatcher()
  function handleClick() {
    track('HotelCard Clicked', {
      propertyName: hotelProperty.name,
      propertyId: hotelProperty.id,
    })
    fetching = true
    dispatch('click', hotelProperty.id)
  }

  function handleMouseEnter() {
    dispatch('preload', hotelProperty.id)
  }

  function handleMouseLeave() {
    dispatch('removeHover')
  }
</script>

<div
  class={`flex sm:flex-col cursor-pointer relative ${fetching ? 'animate-pulse' : ''}`}
  on:click|preventDefault|stopPropagation={handleClick}
  on:mouseenter={handleMouseEnter}
  on:mouseleave={handleMouseLeave}
  on:touchstart={handleClick}
>
  <ImageCarousel {isMarker} {isModal} {photos} />
  {#if discount > 2}
    <Tooltip title="Temporary discount">
      <div
        class={`absolute hidden sm:flex items-center justify-center h-8 px-2 bg-white bg-opacity-100 rounded-lg shadow-lg left-2 top-2 py-auto`}
      >
        <span class="flex text-sm font-semibold">{`${discount}% off`}</span>
      </div>
    </Tooltip>
  {/if}

  {#if gem || hotelProperty?.hasMemberRate}
    <div class="flex items-center gap-x-2 z-[12] absolute left-2 sm:left-auto sm:right-2 top-2">
      {#if gem}
        <Tooltip title="Superior guest ratings">
          <span
            class="flex items-center justify-center w-8 h-8 bg-white bg-opacity-100 rounded-full shadow-lg"
          >
            <img src="/marketing/icons/gem.svg" height={16} width={16} alt="gem" />
          </span>
        </Tooltip>
      {/if}
      {#if hotelProperty?.hasMemberRate}
        <Tooltip title="Member prices available">
          <span
            class="flex items-center justify-center w-8 h-8 bg-white bg-opacity-100 rounded-full shadow-lg"
          >
            <img src="/marketing/icons/member.svg" height={17} width={17} alt="member rate icon" />
          </span>
        </Tooltip>
      {/if}
    </div>
  {/if}

  <a
    href="/stays/{hotelProperty.id}"
    on:click|preventDefault
    class={`flex flex-col w-full truncate justify-between sm:justify-start items-start gap-y-[0.5] px-2 sm:px-3 py-3`}
  >
    <h4 class="w-full font-semibold text-base leading-5 tracking-tight truncate">
      {hotelProperty.name}
    </h4>
    <div class="flex-col w-full flex">
      <div class="flex items-center justify-between w-full">
        <div class="flex items-center justify-start gap-x-2">
          <span class="uppercase text-sm sm:text-base"
            >{findCategoryName(hotelProperty.category)}</span
          >
          {#if hotelProperty.starRating}
            <div class="flex items-center">
              <img
                src={'/marketing/icons/rating-star.svg'}
                class="h-[14px] mr-1 -mt-[2px] w-4"
                width={16}
                height={14}
                loading="lazy"
                alt={`star icon`}
              />
              <span class="text-base">{hotelProperty.starRating}</span>
            </div>
          {/if}
        </div>
        <span class="font-medium text-sm sm:text-base">
          <Amount
            amount={toAmount(hotelProperty.minInclusivePrice / numNights ?? 0, currency)}
            options={{
              currencyDisplay: 'narrowSymbol',
              // notation: 'compact',
              maximumFractionDigits: 1,
            }}
            rounded
          />+
        </span>
      </div>
      <div class="flex items-center justify-between w-full text-xs text-gray-500">
        <span class="mr-4 text-xs text-gray-500 truncate">
          {numBedroomDescription}
        </span>
        {#if numNights > 1}
          <span class="flex-shrink-0">
            <Amount
              amount={toAmount(hotelProperty.minInclusivePrice ?? 0, currency)}
              options={{
                currencyDisplay: 'narrowSymbol',
                // notation: 'compact',
                maximumFractionDigits: 1,
              }}
              rounded
            /> total
          </span>
        {/if}
      </div>
    </div>
  </a>
</div>
