import {
  HotelSearchQuery_RateOption,
  HotelSearchQuery_SalesChannel,
} from '$lib/gen/hotel/v1/query_pb'
import {
  ExpediaRateOption,
  RoomRateSalesChannel,
  ExpediaSalesEnvironment,
} from '$lib/graphql/enums'
import { SalesChannel, SalesEnvironment, RateOption } from '$lib/gen/rates/v1/rate_pb'

export function gqlSalesChannelFromSolution(
  ch: HotelSearchQuery_SalesChannel,
): RoomRateSalesChannel {
  switch (ch) {
    case HotelSearchQuery_SalesChannel.MOBILE_WEB:
      return RoomRateSalesChannel['MobileWeb']
    default:
      return RoomRateSalesChannel['Website']
  }
}

export function gqlRateOptionFromSolution(ch: HotelSearchQuery_RateOption): ExpediaRateOption {
  switch (ch) {
    case HotelSearchQuery_RateOption.MEMBER:
      return ExpediaRateOption['Member']
    default:
      return ExpediaRateOption['None']
  }
}

export function pbRateOptionFromGql(ch: ExpediaRateOption): RateOption {
  return RateOption.UNSPECIFIED
  // switch (ch) {
  //   case ExpediaRateOption['Member']:
  //     return RateOption.MEMBER
  //   default:
  //     return RateOption.UNSPECIFIED
  // }
}

export function pbSalesChannelFromGql(ch: RoomRateSalesChannel): SalesChannel {
  return SalesChannel.META
  // switch (ch) {
  //   case RoomRateSalesChannel['MobileWeb']:
  //     return SalesChannel.MOBILE_WEB
  //   default:
  //     return SalesChannel.WEBSITE
  // }
}

export function pbSalesEnvironmentFromGql(ch: ExpediaSalesEnvironment): SalesEnvironment {
  switch (ch) {
    case ExpediaSalesEnvironment['HotelOnly']:
      return SalesEnvironment.HOTEL_ONLY
    default:
      return SalesEnvironment.HOTEL_ONLY
  }
}
