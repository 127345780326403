<script lang="ts" context="module">
  export type LocationItem = { value: string; label: string }

  export type LocationEventTypes = {
    change: any
    clear: void
  }

  export const defaultLocations = [
    { value: 'loc_2CFCwMJHXCTPQgnFHK1Ofqvwdxi', label: 'Tulum, Tulum, Quintana Roo, Mexico' },
    { value: 'loc_2CFB31bEYkglMHGcS6m0WWejoOx', label: 'Istanbul, Istanbul, Turkey' },
    { value: 'loc_275QDqzoOiEfAFPvJT0AfclU39r', label: 'Paris, France' },
    { value: 'loc_27vuhWSA0D8tbG7rJKMqpFnzSR9', label: 'Sydney, New South Wales, Australia' },
    { value: 'loc_2CFFwyc6ICEBheIK4ZXI9wtGFGC', label: 'Canggu, Bali, Indonesia' },
    {
      value: 'loc_27L3bqpFAqGLJxAVrsVoLWDYmaU',
      label: 'New York, New York, United States of America',
    },
    { value: 'loc_27L3YN47BccYvJpkOFzSxw5DQJB', label: 'Dubai, Dubai, United Arab Emirates' },
    { value: 'loc_27jh930m26W35s8kpPcU9mB56v9', label: 'Miami, Florida, United States of America' },
    { value: 'loc_2CFBnMn7BWxpniMGsmZWVZihxDT', label: 'Tokyo, Tokyo Prefecture, Japan' },
    {
      value: 'loc_2CFEHRKzBJhS15ezprLtuVz6ChU',
      label: 'Chaing Mai, Chiang Mai Province, Thailand',
    },
    { value: 'loc_2CFEiPU0OczHJHO4asd56rk9jrD', label: 'Mykonos, South Aegean, Greece' },
    { value: 'loc_26Z1swQajiizu204CGPA92KUNT7', label: 'Austin, Texas, United States of America' },
    { value: 'loc_2CFBg3K2s3lM4ZHmT6z9qQirwjW', label: 'Dublin, Ireland' },
    { value: 'loc_2CFD6EgfTBHGfHRJqVdjVtXebHs', label: 'Tamarindo, Guanacaste, Costa Rica' },
    {
      value: 'loc_2CFBmurwz2aRRqVHoHGnDSG0L3Z',
      label: 'Seattle, Washington, United States of America',
    },
    { value: 'loc_2CFF9CDhzlaHrKJZi9eyo5ybLnE', label: 'Oslo, Norway' },
    { value: 'loc_2CFBn9fNX2cpkX4iwhcycjeMdUq', label: 'Vienna, Austria' },
    { value: 'loc_2CFD2B8hp5JPKS5STHKgI2e39yM', label: 'Split, Split-Dalmatia, Croatia' },
    { value: 'loc_2CFBnLw4Ep8ayww1BIzWXpaGOGf', label: 'Budapest, Hungary' },
    { value: 'loc_2CFBoAgFpg2EnC1cWvW6AFlDaGw', label: 'Muscat, Muscat Governorate, Oman' },
  ]
</script>

<script lang="ts">
  import { useId } from '$lib/hooks/use-id'
  import { debounce } from 'lodash-es'
  import config from 'src/config'
  import { createEventDispatcher, tick } from 'svelte'
  import Select from 'svelte-select/no-styles/Select.svelte'
  import { Client } from 'typesense'
  import type { SearchParams } from 'typesense/lib/Typesense/Documents'
  import Item from './LocationSelectItem.svelte'
  import LocationIcon from './LocationIcon.svelte'
  import LocationSelectList from './LocationSelectList.svelte'

  const client = new Client({
    apiKey: config.typesenseApiKey,
    nodes: [
      {
        host: '9c0onsfy6hdrqea3p.a1.typesense.net',
        port: 443,
        protocol: 'https',
      },
    ],
  })

  export let items: LocationItem[] = defaultLocations
  export let value: LocationItem
  export let className = ''
  export { className as class }

  type Hit = {
    center: [number, number]
    country_id: string
    expedia_region_id: number
    id: string
    long_name: string
    poi_type: string
    short_name: string
    slug: string
    timezone: string
    type: string
  }

  const dispatch = createEventDispatcher()
  const debouncedDispatch = debounce(dispatch, 500, { leading: true })

  function handleSelect(event) {
    tick().then(() => debouncedDispatch('change', event.detail))
  }

  function handleClearLocation() {
    tick().then(() => dispatch('clear'))
  }

  const id = useId()

  function makeRefinement(value: string) {
    const query: SearchParams = {
      q: value,
      query_by: 'short_name,long_name',
      filter_by: 'type:Locality',
      sort_by: '_text_match:desc',
      prioritize_exact_match: true,

      // group_by: 'parent_id',
      // limit_hits: 10,
      group_limit: 10,
    }

    return client
      .collections<Hit>(config.searchDatasetPrefix + '-locations')
      .documents()
      .search(query)
      .then((res) => {
        return res.grouped_hits.flatMap((hit) => hit.hits).map((hit) => hit.document)
      })
  }

  async function searchForLocations(filterText: string): Promise<LocationItem[]> {
    const results = await makeRefinement(filterText)
    return results.map((loc) => ({ label: loc.long_name, value: loc.id }))
  }
</script>

<div
  class={className + ' flex group relative items-center w-full h-12 md:h-14 lg:h-14 overflow-clip'}
>
  <Select
    id={'select-' + id}
    class={'svelte-select-location w-full min-w-full overflow-hidden rounded-md min-h-full h-full relative flex items-center px-4 bg-white'}
    {items}
    {value}
    on:select={handleSelect}
    isClearable={false}
    {Item}
    List={LocationSelectList}
    listAutoWidth={true}
    hideEmptyState={false}
    isFocused={false}
    listOpen={false}
    loadOptions={searchForLocations}
    listPlacement="bottom"
    placeholder="Type your destination..."
    debounceWait={100}
    listOffset={0}
    Icon={LocationIcon}
    getSelectionLabel={(item) =>
      `<span class="w-full overflow-hidden text-sm text-black truncate">${item.label}</span>`}
  />
  <button
    class="absolute z-20 items-center justify-center hidden w-10 h-10 p-4 rounded-full group-hover:flex right-2 disabled:bg-white disabled:cursor-not-allowed bg-grey-main hover:bg-grey-md"
    on:click={handleClearLocation}
    title={'clear'}
  >
    <img
      src={'/marketing/icons/ex.svg'}
      class="h-[12px]"
      width={8}
      height={12}
      loading="lazy"
      alt={`clear icon`}
    />
  </button>
</div>

<style global lang="postcss">
  .svelte-select-location input {
    @apply absolute appearance-none bg-transparent text-black cursor-pointer text-sm w-full h-full border-none ml-3 md:ml-4 lg:ml-5 outline-none focus:outline-none focus:ring-0;
  }

  .a11y-text {
    @apply sr-only;
  }

  /* .svelte-select .selected-item {
    @apply overflow-x-hidden pr-5 pl-2 md:pl-4 focus:outline-none truncate text-ellipsis w-full;
  } */

  /* .selection {
    @apply flex items-center;
  } */
  /* .svelte-select .clear-select {
    @apply pointer-events-auto;
  } */

  /* .svelte-select.focused .icons,
  .svelte-select .chevron:hover,
  .svelte-select .clear-select:hover {
    @apply text-black;
  } */

  /* .list {
    @apply ;
  } */

  .items {
    @apply cursor-pointer mx-0 rounded-none max-w-full text-black truncate text-sm overflow-ellipsis overflow-hidden whitespace-nowrap;
  }

  /* .item.group-item {
    @apply px-10;
  } */
  /*
  .item:active {
    @apply bg-grey-md;
  }

  .item.active {
    @apply bg-grey-md;
  }

  .item.not-selectable {
    @apply opacity-50;
  }

  .item.first {
    @apply rounded-t-sm;
  } */

  /* .item.hover:not(.active) {
    @apply bg-grey-md;
  } */

  /* .svelte-select-list {
    @apply left-12;
  } */

  /* .list .empty {
    @apply text-center py-5 opacity-0 hidden shadow-none border-none outline-none divide-y-0 focus:outline-none transform-gpu;
  } */

  /*
  .svelte-select .icons {
    @apply absolute flex items-center right-0 text-black top-0 bottom-0;
  }

  .svelte-select .icons > * {
    @apply transition-colors ease-in-out duration-200;
  } */

  /*
  .list .list-group-title {
    @apply text-gray-600 cursor-default text-sm font-medium h-10 leading-10 px-5 overflow-ellipsis whitespace-nowrap uppercase;
  } */

  /* .svelte-select.focused {
    @apply outline-none;
  } */

  /* .svelte-select.disabled {
    @apply bg-grey-dk border-grey-main text-black;
  } */

  /* .svelte-select.disabled input {
    @apply placeholder:text-grey-dk placeholder:opacity-100 px-0 mx-0;
  } */

  /* .svelte-select {
    position: relative !important;
  } */

  /*
  .emoji {
    @apply mr-1 md:mr-2 text-2xl;
  } */

  /* .svelte-select .clear-select {
    @apply h-5 text-grey-dk flex-none w-9;
  } */
  /* .svelte-select.show-chevron {
    @apply w-full;
  } */

  /* .svelte-select .chevron {
    @apply pt-1 pl-3 md:pl-4 w-8 h-6 text-black cursor-pointer -mt-1;
  }

  .svelte-select.error {
    @apply border-red-500 bg-white;
  } */
</style>
