import { writable, type Readable } from 'svelte/store'

type SearchBoundsStore = {
  bounds: {
    neLat: number
    neLng: number
    swLat: number
    swLng: number
  }
}

const store = writable<SearchBoundsStore>({ bounds: null })

function setBounds(bounds: { neLat: number; neLng: number; swLat: number; swLng: number }) {
  store.update((state) => ({ ...state, bounds }))
}

const searchMapBounds: Readable<SearchBoundsStore> & { setBounds: typeof setBounds } = {
  subscribe: store.subscribe,
  setBounds,
}

export default searchMapBounds
