<script lang="ts">
  import GoogleSdk from './GoogleSdk.svelte'
  import { createEventDispatcher } from 'svelte'
  import type { HotelCardFieldsFragment } from '$lib/queries/fragments/generated/HotelCardFields'
  import type { SearchLocationFieldsFragment } from '$lib/queries/fragments/generated/SearchlocationFields'
  import config from 'src/config'
  import HotelCard from '../hotels/HotelCard.svelte'
  import Placeholder from '../travel-restrictions/map/Placeholder.svelte'
  import GoogleSearchMarker from './GoogleSearchMarker.svelte'
  import MapHotelPopup from '../hotels/MapHotelPopup.svelte'
  import Spinner from '../Spinner.svelte'

  export let hotels: HotelCardFieldsFragment[] = []
  export let mapOpen = false
  export let isMobile = false
  export let numNights = 2
  export let hoveringHotelId: string = null
  export let destination: SearchLocationFieldsFragment = null
  export let nextIntentID: string | null = null
  export let isModal = false
  export let mapSearchCoordinates = null
  export let fetching: boolean = false
  export let currency: string = 'USD'

  let mapLoaded = false
  const apiKey = config.googleMapsToken
  const defaultOptions = {
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: true,
    fullscreenControl: !isMobile,
    gestureHandling: 'greedy',
    minZoom: 3,
    styles: [{ featureType: 'poi.business', stylers: [{ visibility: 'off' }] }],
  }

  $: lat = destination?.lat ?? 0
  $: lng = destination?.lng ?? 0
  let map: google.maps.Map
  let mapElement: HTMLDivElement
  let zoom = 12
  $: hasMap = map != null
  let selectedHotelID: string = null

  $: if (hasMap && mapOpen && !fetching) {
    map?.setCenter({ lng, lat })
    if (hotels != null && hotels?.length > 0) {
      let boundsObj = new google.maps.LatLngBounds()
      hotels.forEach((h) => {
        boundsObj.extend({ lat: h.location?.lat, lng: h.location?.lng })
      })
      map?.fitBounds(boundsObj)
    } else {
      map?.setZoom(zoom)
    }
  }

  function initialize(event: CustomEvent<typeof google>) {
    const google = event.detail // NOTE: Pull loaded SDK from event
    if (mapElement == null) return
    let center = new google.maps.LatLng({ lat, lng })
    map = new google.maps.Map(
      mapElement,
      Object.assign(
        {
          center,
          zoom,
        },
        defaultOptions,
      ),
    )
    map?.setClickableIcons(false)

    google?.maps?.event?.addListener(map, 'dragend', () => {
      const location = map?.getCenter()
      center = location
      dispatch('recentre', { location })
    })

    google?.maps?.event?.addListener(map, 'click', (event) => {
      selectedHotelID = null
    })

    dispatch('ready')
    mapLoaded = true
  }

  const dispatch = createEventDispatcher()

  function showCard(id: string) {
    if (selectedHotelID == id) {
      selectedHotelID = null
    } else {
      selectedHotelID = id
    }
  }

  function searchBounds() {
    const neLat = map.getBounds().getNorthEast().lat()
    const neLng = map.getBounds().getNorthEast().lng()
    const swLat = map.getBounds().getSouthWest().lat()
    const swLng = map.getBounds().getSouthWest().lng()

    dispatch('bounds', { neLat, neLng, swLat, swLng })
  }
</script>

<GoogleSdk {apiKey} on:ready|once={initialize} />

<div
  class={`relative overflow-hidden h-full ${
    isModal ? '' : 'lg:h-[calc(100vh-6rem)] lg:sticky lg:top-20 lg:rounded-2xl'
  } w-full`}
>
  {#if hasMap}
    {#each hotels as hotel (hotel.id)}
      <div class="overflow-hidden">
        <GoogleSearchMarker
          {map}
          minInclusivePrice={hotel?.minInclusivePrice}
          {numNights}
          {currency}
          isActive={hoveringHotelId == hotel?.id || selectedHotelID == hotel.id}
          latitude={hotel?.location?.lat}
          longitude={hotel?.location?.lng}
          on:click={(event) => {
            event.preventDefault()
            event.stopPropagation()
            showCard(hotel?.id)
          }}
          on:touchstart={() => {
            showCard(hotel?.id)
          }}
        >
          {#if selectedHotelID == hotel.id}
            <div
              class="hidden sm:block absolute z-50 w-[275px] -translate-x-[118px] bg-white shadow-lg translate-y-2 rounded-2xl"
            >
              <MapHotelPopup
                hotelProperty={hotel}
                isMarker={true}
                {isModal}
                {numNights}
                {currency}
                fetching={nextIntentID == hotel?.id}
                on:click
                on:touchstart
                on:preload
              />
            </div>
          {/if}
        </GoogleSearchMarker>

        {#if selectedHotelID == hotel.id}
          <div
            class="fixed bottom-6 left-2 right-2 z-50 w-[calc(100vw-1rem)] sm:hidden bg-white shadow-lg rounded-lg sm:rounded-2xl"
          >
            <MapHotelPopup
              hotelProperty={hotel}
              isMarker={true}
              {isModal}
              {numNights}
              {currency}
              fetching={nextIntentID == hotel?.id}
              on:click
              on:touchstart
              on:preload
            />
          </div>
        {/if}
      </div>
    {/each}
  {/if}

  <button
    on:click={searchBounds}
    class="absolute cursor-pointer text-sm font-semibold z-20 py-2 px-4 shadow-md top-2 left-2 bg-white rounded flex items-center gap-x-2"
  >
    {#if fetching == true}<Spinner size="sm" />{/if}
    <span>Search This Area</span>
  </button>

  <div class={`w-full h-full ${isModal ? '' : 'lg:rounded-2xl'}`} bind:this={mapElement} />

  {#if !mapLoaded}
    <div class="absolute inset-0 z-20 lg:rounded-2xl overflow-clip">
      <Placeholder class="w-full h-full" />
    </div>
  {/if}
</div>
