<script lang="ts">
  import type CustomMarker from '$lib/map/CustomMarker'
  import { toAmount } from '$lib/utils/currency'
  import { onDestroy, onMount } from 'svelte'
  import Amount from '../ui/Amount.svelte'

  export let latitude: number
  export let longitude: number
  export let minInclusivePrice: number
  export let isActive = false
  export let numNights = 2
  export let map: google.maps.Map
  export let currency: string = 'USD'

  let markerComponent = null
  let marker: CustomMarker

  onMount(async () => {
    const Marker = await import('$lib/map/CustomMarker')

    if (markerComponent != null) {
      marker = new Marker.default(markerComponent, map, {
        lat: latitude,
        lng: longitude,
      })
    }
  })

  onDestroy(() => {
    if (marker) marker.remove()
    if (markerComponent?.parentElement) {
      markerComponent?.parentElement?.removeChild(markerComponent)
    }
  })
</script>

<div
  bind:this={markerComponent}
  class={`hover:z-50 -translate-x-1/2 -translate-y-1/2 ${isActive ? 'z-40' : 'z-10'} `}
>
  <button
    on:click
    on:touchstart
    class={`rounded-2xl shadow px-[8px] hover:scale-110 hover:shadow-lg scale-100 transition-all text-sm font-medium py-[1px] border-grey-dk rounded-full' ${
      isActive ? 'bg-black text-white z-40' : 'bg-white text-black z-10'
    }`}
  >
    <Amount
      amount={toAmount(minInclusivePrice / numNights ?? 0, currency)}
      options={{
        currencyDisplay: 'narrowSymbol',
        // notation: 'compact',
        maximumFractionDigits: 1,
      }}
      rounded={true}
    />
  </button>

  <slot />
</div>
