<script lang="ts" context="module">
  export type Item = { iconId: string; value: string; label: string; isGroupItem?: boolean }
</script>

<script lang="ts">
  export let item: Item
  export let isActive = false
  export let isFirst = false
  export let isHover = false
  export let isSelectable = false
</script>

<div
  class:active={isActive}
  class:first={isFirst}
  class:hover={isHover}
  class:group-item={item.isGroupItem}
  class:not-selectable={!isSelectable}
  class={`flex items-center p-1 lg:p-2 ${
    isHover ? 'bg-grey-md' : ''
  } active:bg-grey-md hover:bg-grey-md truncate text-base text-ellipsis cursor-pointer overflow-hidden whitespace-nowrap min-w-fit w-full outline-none select-none pr-0 focus:ring-0 leading-6`}
>
  <!-- {#if item.iconId}
    <span class="text-2xl">
      {countryCodeEmoji(item.iconId)}
    </span>
  {/if} -->
  <span class="pl-1 sm:pl-2 font-normal">
    {item.label}
  </span>
</div>
